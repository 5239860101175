import moment from "moment";
import React, {
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { createSelector } from "reselect";
import { ADMIN_URL } from "../../../../config";
import { getHasEngineerPrivileges } from "../../../../core/auth";
import { notificationsActions } from "../../../../core/notifications";
import {
  getAddNewOrganisationError,
  getDomainConfirmations,
  getDomainLicenseRetry,
  getExistingTeacherLicenseError,
  getOrganisations,
  getOrganisationSaving,
  organisationsActions,
} from "../../../../core/organisations";
import CopyIcon from "../../../../images/svg/copy.svg";
import ExternalIcon from "../../../../images/svg/external-link.svg";
import { isValidXeroUrl } from "../../../../utils/validators";
import Button from "../../button";
import FlatButton from "../../button/flat";
import Content from "../../content";
import DatePicker from "../../forms/datepicker";
import DisabledSection from "../../forms/disabled-section";
import Dropdown from "../../forms/dropdown";
import Form from "../../forms/form";
import FormActions from "../../forms/form-actions";
import FormButton from "../../forms/form-button";
import FormLoading from "../../forms/form-loading";
import TextArea from "../../forms/text-area";
import TextBox from "../../forms/text-box";
import Header, { HeaderActions } from "../../header";
import Modal from "../../modal";
import SvgIcon from "../../ui/svg-icon";
import DomainConfirmation from "./confirmation";
import domainBlocklist from "./domain-blocklist";
import DomainLicenseRetry from "./domain-license-retry";
import DomainPreview from "./domain-preview";
import Features from "./features";
import { getDomainArray, isValidDomain } from "./helpers";
import OrgDomains, { OrgUserType } from "./org-domains";
import OrgTypeHandler from "./org-type-handler";
import Settings from "./settings";

const TSMs = [
  "Adam Moncrief",
  "Alberto Venturini",
  "Ali Taki",
  "Amelia Hobbs",
  "April Hayward",
  "Catherine Cappiello",
  "Chanel Dublin",
  "David Swift",
  "Janet Burnett",
  "Jen Cousins",
  "Jon Smith",
  "Kathleen Swift",
  "Matthew Schopp",
  "Nina Jenkins",
  "Tom Towers",
  "Vicky Holt",
  "Will Pursey",
];

export const tsmOptions = [
  {
    value: null,
    label: "None",
  },
  ...TSMs.map(tsm => ({ value: tsm })),
];

const initialState = {
  adminEmails: "",
  dealAmount: 0,
  demo: false,
  domainConfirmation: null,
  domainLicenses: "",
  done: false,
  endDate: null,
  features: { visitLibraries: true },
  hadLicenses: false,
  id: null,
  isPilot: false,
  isReady: false,
  licenseCount1000: 0,
  name: "",
  previousDomains: null,
  previousAdmins: null,
  quoteId: "",
  reseller: false,
  schoolSubscriptionUUID: null,
  showLicenseRemovalWarning: false,
  startDate: null,
  domainMatchers: [],
  submitted: false,
  tsm: null,
  xeroId: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

function AddOrganisation(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const adminEmailsRef = useRef();
  const domainsRef = useRef();
  const { orgId } = useParams();
  const isEditMode = Boolean(orgId);
  const [isSaving, setIsSaving] = useState(false);

  const {
    addErrorMessage,
    addNewOrganisationError,
    addNotification,
    getOrganisationById,
    organisationSaving,
    existingTeacherLicenseError,
    domainConfirmations,
    domainLicenseRetry,
    hasEngineerPrivileges,
    setExistingTeacherLicenseError,
  } = props;

  const setState = payload => {
    dispatch({
      type: "SET_STATE",
      payload,
    });
  };

  useEffect(() => {
    if (!isEditMode) {
      const today = moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const inOneYear = today.clone().add(1, "years");
      setState({
        startDate: today,
        endDate: inOneYear,
        isReady: true,
      });
    }
  }, [isEditMode]);

  useEffect(() => {
    if (organisationSaving && !isSaving) {
      setIsSaving(true);
    }
    if (!organisationSaving && isSaving) {
      setIsSaving(false);
      if (!addNewOrganisationError && !existingTeacherLicenseError) {
        setState({
          done: true,
        });
      }
    }
  }, [
    organisationSaving,
    isSaving,
    addNewOrganisationError,
    existingTeacherLicenseError,
  ]);

  useEffect(() => {
    const onLoad = org => {
      if (!org) {
        setState({
          done: true,
        });
        return addErrorMessage("Organisation data could not be loaded");
      }
      const {
        admins,
        dealAmount,
        demo,
        domains,
        endDate,
        features,
        id,
        isPilot,
        licenses,
        name,
        quoteId,
        reseller,
        schoolSubscriptionUUID,
        startDate,
        domainMatchers,
        tsm,
        xeroId,
      } = org;

      let previousAdmins;
      let previousDomains;
      let adminEmails = "";
      let domainLicenses = "";
      if (admins.length) {
        // check to prevent all admins are removed at once
        previousAdmins = true;
        adminEmails = admins.map(admin => admin.email).join("\n");
      }

      if (domains.length) {
        previousDomains = domains.map(dom => dom.domain);
        domainLicenses = previousDomains.join("\n");
      }

      let licenseCount1000 = 0;
      if (licenses.length) {
        for (const license of licenses) {
          if (!license.applyToAll) {
            licenseCount1000 = license.quantity || 0;
          }
        }
      }
      setState({
        adminEmails,
        dealAmount: dealAmount ? dealAmount : 0,
        demo,
        domainLicenses,
        endDate: moment(endDate),
        features,
        hadLicenses: licenseCount1000 > 0,
        id,
        isPilot,
        isReady: true,
        licenses,
        licenseCount1000,
        name,
        previousDomains,
        previousAdmins,
        reseller,
        quoteId: quoteId ? quoteId : "",
        startDate: moment(startDate),
        domainMatchers,
        xeroId: xeroId ? xeroId : "",
        schoolSubscriptionUUID,
        tsm: tsm || null,
      });
    };
    if (isEditMode) {
      getOrganisationById(orgId, onLoad);
    }
  }, [isEditMode, orgId, addErrorMessage, getOrganisationById]);

  const hasDomainLicense = () => {
    const { licenses } = state;
    if (!licenses) {
      return false;
    }
    let hasDomainLicense = false;
    Object.keys(licenses).forEach(key => {
      if (licenses[key].applyToAll) {
        hasDomainLicense = true;
      }
    });
    return hasDomainLicense;
  };

  const hasNonDomainLicenses = parseInt(state.licenseCount1000, 10) > 0;
  const isDomainDisabled = state.hadLicenses || hasNonDomainLicenses;
  const isQuotaDisabled = hasDomainLicense() || state.domainLicenses.length;

  const handleSubmit = e => {
    e.preventDefault();

    setState({
      submitted: true,
    });
    if (Object.keys(errors()).length) {
      return;
    }
    const { domainLicenses, hadLicenses, licenseCount1000 } = state;
    if (
      hadLicenses &&
      !parseInt(licenseCount1000, 10) &&
      !domainLicenses.length
    ) {
      setState({
        showLicenseRemovalWarning: true,
      });
    } else {
      save();
    }
  };

  const save = e => {
    const { saveOrganisation } = props;
    const {
      adminEmails,
      dealAmount,
      demo,
      domainLicenses,
      endDate,
      features,
      id,
      isPilot,
      licenseCount1000,
      licenses,
      name,
      previousDomains,
      previousAdmins,
      reseller,
      quoteId,
      schoolSubscriptionUUID,
      startDate,
      domainMatchers,
      tsm,
      xeroId,
    } = state;

    let domainArray = getDomainArray(domainLicenses);

    const domainsToIgnore = [];
    domainArray = domainArray.filter(domain => {
      let blocklisted = false;
      domainBlocklist.forEach(blocklistedDomain => {
        if (domain.indexOf(blocklistedDomain) > -1) {
          domainsToIgnore.push(domain);
          blocklisted = true;
        }
      });
      if (!isValidDomain(domain) && !domainsToIgnore.includes(domain)) {
        domainsToIgnore.push(domain);
        blocklisted = true;
      }
      return !blocklisted;
    });
    const domainsToRemove = [];
    if (previousDomains) {
      previousDomains.forEach(domain => {
        if (!domainArray.includes(domain)) {
          domainsToRemove.push(domain);
        }
      });
    }
    const domainsToAdd = domainArray.filter(domain => {
      return !previousDomains || !previousDomains.includes(domain);
    });

    const featureFlags = {
      ...features,
    };

    const domainsRemoved = previousDomains && domainArray.length === 0;

    // Turn org libraries off if all domains are removed
    if (domainsRemoved) {
      featureFlags.orgLibraries = false;
    }

    let payload;

    payload = {
      adminEmails,
      dealAmount,
      demo,
      domainLicenses,
      domainsToAdd,
      domainsToIgnore,
      domainsToRemove,
      endDate: endDate.valueOf(),
      features: featureFlags,
      id,
      isPilot,
      licenses,
      licenseCount1000: parseInt(licenseCount1000, 10),
      name,
      domainMatchers,
      previousDomains,
      previousAdmins,
      reseller,
      quoteId,
      schoolSubscriptionUUID,
      startDate: startDate.valueOf(),
      tsm,
      xeroId,
      isNewOrg: !isEditMode,
    };
    if (
      domainsToAdd.length ||
      domainsToRemove.length ||
      domainsToIgnore.length
    ) {
      setState({
        domainConfirmation: {
          ...payload,
        },
      });
    } else {
      saveOrganisation(payload);
    }
  };

  const handleConfirmationConfirm = () => {
    const { saveOrganisation } = props;
    const { domainConfirmation } = state;
    saveOrganisation({ ...domainConfirmation });
    setState({
      domainConfirmation: null,
    });
  };

  const handleConfirmationCancel = () => {
    setState({
      domainConfirmation: null,
    });
  };

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleAdminsPaste = e => {
    handleSpecialPaste(e, "adminEmails", adminEmailsRef);
  };

  const handleSpecialPaste = (e, name, ref) => {
    let paste = (e.clipboardData || window.clipboardData).getData("text");
    const { selectionStart, selectionEnd, value } = e.target;
    let v = `${value.slice(0, selectionStart)}${value.slice(selectionEnd)}`;
    const pastedValue = paste.toLowerCase();
    const combinedPaste = [
      v.slice(0, selectionStart),
      pastedValue,
      v.slice(selectionStart),
    ].join("");
    setState(
      {
        [`${name}`]: combinedPaste,
      },
      () => {
        ref.selectionStart = selectionStart + pastedValue.length;
        ref.selectionEnd = selectionStart + pastedValue.length;
      },
    );
    e.preventDefault();
    e.stopPropagation();
  };

  const handleNumberBlur = (name, e) => {
    const { value } = e.target;
    if (!value || !value.length) {
      setState({
        [name]: 0,
      });
    }
  };

  const errors = () => {
    const { name, xeroId } = state;
    let errors = {};
    if (!name || !name.length) {
      errors["name"] = "Name is required";
    }
    if (xeroId && xeroId.length && !isValidXeroUrl(xeroId)) {
      errors["xeroId"] = "Enter the full URL from Xero";
    }
    return errors;
  };

  const {
    adminEmails,
    dealAmount,
    demo,
    domainConfirmation,
    domainLicenses,
    done,
    endDate,
    id,
    isPilot,
    isReady,
    licenseCount1000,
    name,
    quoteId,
    reseller,
    showLicenseRemovalWarning,
    startDate,
    domainMatchers,
    submitted,
    tsm,
    xeroId,
  } = state;

  if (done) {
    return <Navigate to="/organisations" />;
  }

  const licenseInputs = (
    <Fragment>
      <TextBox
        label="1000 book licenses"
        type="number"
        min={0}
        onChange={e => handleChange("licenseCount1000", e)}
        onBlur={e => handleNumberBlur("licenseCount1000", e)}
        value={licenseCount1000}
        disabled={isQuotaDisabled}
      />
    </Fragment>
  );

  const domainLicenseInput = (
    <TextArea
      label="License domains"
      optional
      height={100}
      onChange={e => handleChange("domainLicenses", e)}
      innerRef={domainsRef}
      value={domainLicenses}
      disabled={isDomainDisabled}
    />
  );

  const tsmEntry = tsmOptions.find(t => t.value === tsm);
  const tsmValue = {
    value: tsmEntry.value,
    label: tsmEntry.label || tsmEntry.value,
  };

  const isDomainOrg = hasDomainLicense() || domainLicenses.length;
  const studentPortfoliosDisabled =
    !isDomainOrg ||
    !domainMatchers.some(
      domain =>
        domain.type === OrgUserType.STUDENT || domain.type === OrgUserType.ALL,
    );

  const copyToClipboard = str => {
    navigator.clipboard.writeText(str);
    addNotification("Copied to clipboard");
  };

  return (
    <div>
      <Header
        title={isEditMode ? "Edit organisation" : "Add new organisation"}
        right>
        {id && (
          <HeaderActions right>
            <Button
              icon={ExternalIcon}
              text
              target="_blank"
              style={{ padding: 0 }}
              href={`${ADMIN_URL}/${id}`}>
              Open in Admin
            </Button>
          </HeaderActions>
        )}
      </Header>
      <Content>
        <Form
          width={450}
          onSubmit={handleSubmit}
          autocomplete={false}
          style={{ marginBottom: 150 }}>
          <div className="form__content">
            {!isReady ? <FormLoading /> : null}
            <TextBox
              label="Name"
              onChange={e => handleChange("name", e)}
              error={submitted && errors()["name"]}
              value={name}
              maxLength={128}
              autoFocus={!isEditMode}
            />
            {isEditMode ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}>
                <span
                  style={{
                    display: "block",
                    fontSize: 12,
                    color: "#40444f",
                    marginRight: 8,
                  }}>
                  ID
                </span>
                {/* biome-ignore lint/a11y/useKeyWithClickEvents: Just a helper span */}
                <span
                  style={{
                    display: "inline-block",
                    fontSize: 12,
                    padding: "5px 10px",
                    borderRadius: 3,
                    background: "#eee",
                    color: "#666",
                    border: "none",
                    cursor: "context-menu",
                  }}
                  title="Click to copy to clipboard"
                  onClick={() => copyToClipboard(id)}>
                  {id}
                </span>
                <button
                  style={{
                    display: "block",
                    fontSize: 12,
                    width: 22,
                    height: 22,
                    padding: 5,
                    background: "#eee",
                    marginLeft: 8,
                    borderRadius: 3,
                    cursor: "pointer",
                    border: "none",
                  }}
                  type="button"
                  title="Click to copy to clipboard"
                  onClick={() => copyToClipboard(id)}>
                  <SvgIcon
                    icon={CopyIcon}
                    name="copy"
                    style={{ fill: "#666" }}
                  />
                </button>
              </div>
            ) : null}
            <div>
              <Settings
                demo={demo}
                onDemoChange={() => setState({ demo: !demo })}
                reseller={reseller}
                onResellerChange={() => setState({ reseller: !reseller })}
                isPilot={isPilot}
                onPilotChange={() => setState({ isPilot: !isPilot })}
              />
              <Features
                features={state.features}
                onChange={features => setState({ features })}
                orgLibrariesDisabled={!isDomainOrg}
                studentPortfoliosDisabled={studentPortfoliosDisabled}
              />
              <DatePicker
                label="Start date"
                value={startDate}
                onChange={e => handleChange("startDate", e)}
              />
              <DatePicker
                label="End date"
                value={endDate}
                onChange={e => handleChange("endDate", e)}
              />
              {isQuotaDisabled ? (
                <DisabledSection title="Organisation has a domain license">
                  {licenseInputs}
                </DisabledSection>
              ) : (
                licenseInputs
              )}
              <TextBox
                label="Deal amount"
                type="number"
                prefix="$"
                min={0}
                onChange={e => handleChange("dealAmount", e)}
                onBlur={e => handleNumberBlur("dealAmount", e)}
                value={dealAmount}
              />
              <TextBox
                label="Xero URL"
                optional
                onChange={e => handleChange("xeroId", e)}
                error={submitted && errors().xeroId}
                value={xeroId}
              />
              <TextBox
                label="Quote ID"
                optional
                onChange={e => handleChange("quoteId", e)}
                value={quoteId}
              />
            </div>
            <TextArea
              label="Admin emails"
              optional
              height={100}
              onPaste={handleAdminsPaste}
              onChange={value =>
                handleChange("adminEmails", value.toLowerCase())
              }
              innerRef={adminEmailsRef}
              value={adminEmails}
            />
            {(() => {
              if (isDomainDisabled) {
                // TODO: we could later add a Delete button for preexisting quotas so we can do the switch without closing the modal
                const tooltipContent = `To enable domains remove the existing quota licenses ${
                  state.hadLicenses ? "and save the org first" : ""
                }`;
                return (
                  <>
                    <span
                      data-tooltip-id="domainLicenseTooltip"
                      data-tooltip-content={tooltipContent}>
                      <DisabledSection title="Organisation has a quota license">
                        {domainLicenseInput}
                      </DisabledSection>
                    </span>
                    <Tooltip id="domainLicenseTooltip" />
                  </>
                );
              }
              return domainLicenseInput;
            })()}
            <OrgDomains
              orgEditMode={isEditMode}
              domains={domainMatchers}
              editable={hasEngineerPrivileges}
              setDomains={domainMatchers => setState({ domainMatchers })}
            />
            <Dropdown
              label="TSM"
              value={tsmValue}
              menuPlacement="top"
              onChange={change => {
                handleChange("tsm", change.value);
              }}
              options={tsmOptions}
            />
            <FormActions>
              <FlatButton link="/organisations">Cancel</FlatButton>
              <FormButton
                type="submit"
                disabled={organisationSaving}
                loading={organisationSaving}
                text={isEditMode ? "Update" : "Add"}
              />
            </FormActions>
          </div>
        </Form>
      </Content>
      {domainConfirmation ? (
        <DomainConfirmation
          domainsToAdd={domainConfirmation.domainsToAdd}
          domainsToIgnore={domainConfirmation.domainsToIgnore}
          domainsToRemove={domainConfirmation.domainsToRemove}
          onConfirm={handleConfirmationConfirm}
          onCancel={handleConfirmationCancel}
        />
      ) : null}
      {existingTeacherLicenseError ? (
        <Modal
          title="Can't add domain license"
          body="You need to set individual licenses to 0 before adding a domain license"
          modal
          onCancel={() => setExistingTeacherLicenseError(false)}
        />
      ) : null}

      {showLicenseRemovalWarning ? (
        <Modal
          title="Remove all licenses?"
          body="If you set the number of licenses to 0, teachers who have already been assigned licenses will have them removed. "
          modal
          onCancel={() => setState({ showLicenseRemovalWarning: false })}
          onConfirm={save}
        />
      ) : null}

      {domainLicenseRetry ? (
        <DomainLicenseRetry
          onSuccess={() => setState({ done: true })}
          {...domainLicenseRetry}
        />
      ) : null}

      {domainConfirmations ? (
        <DomainPreview
          onSuccess={() => setState({ done: true })}
          {...domainConfirmations}
        />
      ) : null}

      <OrgTypeHandler
        isDomainOrg={isDomainOrg}
        isQuotaOrg={hasNonDomainLicenses}
        onBecameDomainOrg={() => {
          // Uncomment for general release
          // setState({
          //   features: {
          //     ...state.features,
          //     orgLibraries: true,
          //   },
          // });
        }}
        onBecameQuotaOrg={() => {
          setState({
            features: {
              ...state.features,
              orgLibraries: false,
            },
          });
        }}
      />
    </div>
  );
}

const mapStateToProps = createSelector(
  getOrganisationSaving,
  getAddNewOrganisationError,
  getOrganisations,
  getExistingTeacherLicenseError,
  getDomainLicenseRetry,
  getDomainConfirmations,
  getHasEngineerPrivileges,
  (
    organisationSaving,
    addNewOrganisationError,
    organisations,
    existingTeacherLicenseError,
    domainLicenseRetry,
    domainConfirmations,
    hasEngineerPrivileges,
  ) => ({
    organisationSaving,
    addNewOrganisationError,
    organisations,
    existingTeacherLicenseError,
    domainLicenseRetry,
    domainConfirmations,
    hasEngineerPrivileges,
  }),
);

const mapDispatchToProps = {
  ...organisationsActions,
  ...notificationsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrganisation);
